import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Modal, Input } from "antd";
import { toast } from "react-toastify";
import Axios from "../../Helpers/Interceptors";
import Loader from "../Loader/Loader";
import * as Yup from "yup";

const GenerateOTP = () => {
  const [loading, setloading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    selection: "email",
    email: "",
    // phoneNumber: "",
  });
  const initialValues1 = {
    otp: Array(6).fill(""),
  };
  const [userData, setuserData] = useState({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [otpModalOpen, setotpModalOpen] = useState(false);

  const showModal = () => {
    setConfirmModalOpen(true);
  };

  const cancelToggle = () => {

    setConfirmModalOpen(false);
  };

  const cancelOtpModalOpen = () => {
    setotpModalOpen(false);
  };

  const validationSchema = Yup.object({
    otp: Yup.array()
      .of(Yup.string().length(1, "Enter one digit").required())
      .required("OTP is required")
      .min(6, `Enter all ${6} digits`),
  });

  const validationSchema1 = Yup.object({
    email: Yup.string()
    .required("Email is required *")
    .email("Email is invalid"),
  });

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"))?.data?.user;
    console.log(data);
    setInitialValues({
      selection:"email",
      email:data.email
    });
    setuserData(data);
  }, []);

  const maskEmail = (email) => {
    const [localPart, domain] = email.split("@");
    return `${localPart.slice(0, 3)}***@${domain}`;
  };

  const maskPhoneNumber = (phoneNumber) => {
    return phoneNumber.slice(0, -3).replace(/\d/g, "*") + phoneNumber.slice(-3);
  };

  const handleSubmit = async (value) => {
    try {
      const obj = {
        type: value.selection,
      };
      if (obj.type == "email") {
        obj.email = userData.email;
      } else {
        obj.phoneNumber = userData.phoneNumber;
      }
      setloading(true);
      const response = await Axios.get(`manageUsers/getOtp/${value.email}`);
      if (response.status === 200) {
        toast.success(
          `PIN sent to your ${obj.type == "email" ? "email address" : "number"}`
        );
        cancelToggle();
        // setotpModalOpen(true);
      } else if (response.status === 401 || response.status === 403) {
        console.error("Error:", response);
        toast.error(response.response.data.message);
      }else{
        console.error("Error:", response);
        toast.error(response.response.data);
      }
      setloading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
      setloading(false);
    }
  };

  const sendOtp = async (value) => {
    try {
      const obj = {
        otp: value.otp.join(""),
      };
      setloading(true);
      const response = await Axios.post(
        `manageUsers/validateOtp/${userData.id}`,
        obj
      );
      if (response.status === 200) {
        toast.success(`Validate Successfully`);
        cancelToggle();
        setotpModalOpen(false);
      } else if (response.status === 401 || response.status === 403) {
        toast.error(response.response.data.message);
      }
      toast.error( response?.response?.data);
      setloading(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
      setloading(false);
    }
  };
  const handleOtpChange = (e, index, otpValues, setFieldValue) => {
    const { value } = e.target;

    // Only allow numeric values
    if (!/^\d*$/.test(value)) return;

    // Update the specific index
    const newOtp = [...otpValues];
    newOtp[index] = value;

    // Update Formik's state
    setFieldValue("otp", newOtp);

    // Auto-focus on the next input if the value is valid
    if (value && index < 6 - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      nextInput?.focus();
    }
  };



  const handleCancel=(resetForm)=>{
    resetForm()
    setotpModalOpen(false);
  }
  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid">
        <div className="signup-content1">
          <div className="heading-content">
            <h2>Generate Pin</h2>
          </div>
          <button
            type="button"
            onClick={showModal}
            className="submit-button3"
            style={{ marginLeft: "10px" }}
          >
            Generate Pin
          </button>
        </div>

        <Modal
          open={confirmModalOpen}
          onCancel={cancelToggle}
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
        >
          <div className="signup-content-model1">
            <div className="heading-content">
              <h2>Send PIN</h2>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema1}
              onSubmit={(values) => {
                handleSubmit(values);
                console.log(values); // Handle form submission here
              }}
            >
              {({ setFieldValue, values,resetForm,errors,touched }) => (
                <Form>
                  {/* <div>
                    <h2>Select Method</h2>
                  </div> */}

                  {/* Email Option with Masked Email */}
                  <div className="mb-2 d-flex">
                  <Field
          style={{ marginRight: "10px" }}
          type="radio"
          name="selection"
          value="email"
          onChange={() => setFieldValue("selection", "email")}
          checked={values.selection === "email"}
        />

<div className="Search-field" style={{width:'100%'}}>
              <label>Email</label>
                    <Field name="email"   >

                      {({ field }) => (

                        // <Input
                        //   {...field}
                        //   style={{ padding: "15px" }}
                        //   value={maskEmail(userData?.email || "")}
                        //   readOnly={userData.role != 'admin'}
                        // />
                        <Input

                        className={`form-input ${
                          errors.email && touched.email ? "error" : ""
                        }`}
                          {...field}
                          style={{ padding: "15px" }}
                         name="email"
                          readOnly={userData.role != 'admin'}
                        />
                      )}
                    </Field>
</div>
                  </div>
                  {/* <ErrorMessage name="email" >
                          {(msg) => (
                            <span className="error-message"style={{marginLeft:'6%'}}>

                              {msg}
                            </span>
                          )}
                        </ErrorMessage> */}
                  {/* Phone Number Option with Masked Phone Number */}
                  {/* <div className="mb-2 d-flex" style={{marginTop:'10px'}}>
                    <input
                      style={{ marginRight: "10px" }}
                      type="radio"
                      name="selection"
                      value="number"
                      onChange={() => setFieldValue("selection", "number")}
                      checked={values.selection === "number"}
                    />
                    <Field name="phoneNumber">
                      {({ field }) => (
                        <Input
                          {...field}
                          style={{ padding: "15px" }}
                          value={maskPhoneNumber(userData?.phoneNumber || "")}
                          readOnly
                        />
                      )}
                    </Field>
                  </div> */}

                  {/* Submit and Close Buttons */}
                  <div
                    className="submit-container1"
                    style={{ marginTop: "20px" }}
                  >
                    <button className="submit-button1" type="submit">
                      Send
                    </button>
                    <button
                      className="submit-button2"
                      type="button"
                      onClick={()=>cancelToggle(resetForm)}
                    >
                      Close
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>

        <Modal
          open={otpModalOpen}
          onCancel={cancelOtpModalOpen}
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
        >
          <div className="signup-content-model1">
            <div className="heading-content">
              <h2>Verify PIN</h2>
            </div>

            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues1}
              onSubmit={(values) => {
                sendOtp(values);
                console.log(values); // Handle form submission here
              }}
            >
              {({ setFieldValue, values, errors, touched ,resetForm}) => (
                <Form>
                {/* OTP Inputs */}
                <div className="otp-inputs" style={{textAlign:'center'}}>
                  {Array(6)
                    .fill()
                    .map((_, index) => (
                      <Input


                        key={index}
                        id={`otp-input-${index}`}
                        className={`form-input ${
                          errors.otp && touched.otp ? "error" : ""
                        }`}
                        value={values.otp[index] || ""}
                        maxLength={1}
                        onChange={(e) =>
                          handleOtpChange(e, index, values.otp, setFieldValue)
                        }
                        style={{ width: "50px", marginRight: "5px", textAlign: "center" }}
                      />
                    ))}
                </div>

                <div className="submit-container1" style={{ marginTop: "20px" }}>
                  <button className="submit-button1" type="submit">
                    Verify
                  </button>
                  <button
                    className="submit-button2"
                    type="button"
                    onClick={()=>{ handleCancel(resetForm)}}
                  >
                    Close
                  </button>
                </div>
              </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default GenerateOTP;
