import Register from '../Components/Register/Register';
import Login from '../Components/Login/Login';
import ForgotPassword from '../Components/ForgotPassword/ForgotPassword';
import UserManagment from '../Components/UserManagment/UserManagment';
import Home from '../Components/Home/Home';
import ResetPassword from '../Components/ResetPassword/ResetPassword';
import UserProfile from '../Components/UserProfile/UserProfile';
import Error from '../Components/Error/Error';
import MyAccount from '../Components/MyAccount/MyAccount';
import Events from '../Components/Events/Events';
import ChangePassword from '../Components/ChangePassword/ChangePassword';
import GernateOTP from '../Components/GernateOTP/GernateOTP';
import AppointmentTable from '../Components/AppointmentTable/AppointmentTable';
import Orders from '../Components/Orders/Orders';

import PatientDetails from '../Components/PatientDetails/PatientDetails';
import Dashboard from '../Components/Dashboard/Dashboard';
import ComplianceAnalyst from '../Components/ComplianceAnalyst/ComplianceAnalyst';

import ChangeLogo from '../Components/ChangeLogo/ChangeLogo';
import Products from '../Components/Products/Products';
import Partners from '../Components/Partners/Partners';
export const routes = () => [
  { path: '/', element: Login , pvtRoute: false,role:['admin','contributor','compliance analyst'] }, // Public route (login)

  {
    path: 'forgot-password',
    element: ForgotPassword,
    role:['admin','contributor','compliance analyst']
  },
  {
    path: 'register',
    element: Register,pvtRoute: false,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'my-account',
    element: MyAccount,
    pvtRoute: true,role:['admin','contributor','compliance analyst'] // Protected route
  },
  {
    path: 'home',
    element: Home,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'reset-password/:id/:check',
    element: ResetPassword,pvtRoute: false,role:['admin','contributor','compliance analyst']
  },



  {
    path: 'change-password',
    element: ChangePassword,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'generate-pin',
    element: GernateOTP,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'user-profile',
    element: UserProfile,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'user-management',
    element: UserManagment,
    pvtRoute: true,role:['admin']
  },
  {
    path: 'events',
    element: Events,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'products',
    element: Products,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'partners',
    element: Partners,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'appointments',
    element: AppointmentTable,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'quality-check',
    element: ComplianceAnalyst,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },

  {
    path: 'orders',
    element: Orders,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },

  {
    path: 'patient-details/:id',
    element: PatientDetails,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },


  {
    path: 'Events',
    element: Events,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'Dashboard',
    element: Dashboard,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },
  {
    path: 'change-logo',
    element: ChangeLogo,
    pvtRoute: true,role:['admin','contributor','compliance analyst']
  },







  { path: '*', element: Error }, // Error route

];
