import React, { useState,useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route,useLocation,useNavigate  } from 'react-router-dom';
import PvtRoute from './Helpers/PvtRoute';
import { routes } from './Helpers/Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Components/SideBar/SideBar'; // Import your sidebar
import "./index.css";
import TopBar from './Components/TopBar/TopBar';
import globalRouter from "./globalRouter.ts";
import { toast } from "react-toastify";
import {  emitEvent  } from './Helpers/utils.js';

import Axios from "./Helpers/Interceptors";
const App = () => {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  const [loading, setloading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  const [userData, setuserData] = useState({});
  const location = useLocation();
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    useEffect(() => {
      const data=JSON.parse(localStorage.getItem('userData'))?.data?.user
      console.log(data)
       setuserData(  data);

      if(JSON.parse(localStorage.getItem('userData'))?.data?.token){
        // checkToken()
      }


      // const loginTime = new Date().getTime(); // Current time in milliseconds
      // localStorage.setItem("loginTime", loginTime);

      // Set a timeout to log the user out after 1 hour (3600000ms)
      const timer = setTimeout(() => {
        logout();
      }, 3600000  );

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);

    }, []);



    useEffect(() => {
      getLogoName()
      const resetTimer = () => {
        const loginTime = localStorage.getItem("loginTime");
        if (loginTime) {
          const elapsedTime = new Date().getTime() - parseInt(loginTime, 10);
          if (elapsedTime >= 3600000  ) {
            logout();
          }
        }
      };

      window.addEventListener("mousemove", resetTimer);
      window.addEventListener("keydown", resetTimer);

      return () => {
        window.removeEventListener("mousemove", resetTimer);
        window.removeEventListener("keydown", resetTimer);
      };
    }, []);


    const getLogoName = async () => {

      try {
        setloading(true);




        let response = await Axios.get("auth/getText");
        if (response.status == 200) {
          // Checks if status code is 200 (OK)
         // Adjust this based on your API response structure

          // Save the updated user data in localStorage
         localStorage.setItem('Logo',response.data.text)
         emitEvent('customEvent', { message: 'Hello from ComponentA!' });
          setloading(false);
        }


      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again.");

        setloading(false);
      }
    };




    const checkToken = async () => {

      try {
        setloading(true)
              const response = await Axios.get(`manageUsers/refreshSite`);
              if (response.status === 200) {  // Checks if status code is 200 (OK)

                setloading(false)

                navigate('/')
              }

          } catch (error) {


        // If error is a CORS issue, you might not have a response object
        if (error.response) {

          toast.error(`Error: ${error.response.data.message || 'An error occurred. Please try again later.'}`);
        } else if (error.request) {

          toast.error('Request was made but no response was received. This could be a CORS issue or network problem.');
        } else {

          toast.error('An error occurred. Please try again later.');

        }
        navigate('/')

        setloading(false);
          }

    };
    const logout = () => {
      // Clear any stored user data (e.g., tokens)
      localStorage.removeItem('loginTime')
      localStorage.removeItem('userData')
      localStorage.removeItem('token')



      navigate("/");
    };
    const showSidebarAndTopbar = ![
      '/', '/register', '/forgot-password'
    ].includes(location.pathname) && !location.pathname.startsWith('/reset-password');
    return (
      <div className={`app ${isSidebarOpen ? 'sidebar-open' : ''}`}> {/* Add class based on sidebar state */}
        {showSidebarAndTopbar && <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />}
        {showSidebarAndTopbar && <TopBar toggleSidebar={toggleSidebar} />}

        <div className={` ${showSidebarAndTopbar ? 'home-section1' : ''}`}> {/* Main content wrapper */}
          <ToastContainer />
          <Routes>
            {routes()?.map((val, i) => (
              <Route
                key={i}
                path={val.path}
                element={

                    <PvtRoute
                      children={val}
                      path={val.path?.split("/")[1]}
                    />

                }
              />
            ))}
          </Routes>
        </div>
      </div>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
