import React, { useState, useEffect } from "react";
import { Table, Button, Space, Pagination, Select } from "antd";
import { useNavigate } from "react-router-dom";

import "./Orders.scss";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import Axios from "../../Helpers/Interceptors";
import {dateFormat} from '../../Helpers/utils'

import Loader from "../Loader/Loader";

const Orders = () => {
  const [loading, setloading] = useState(false);


  const [filters, setFilters] = useState({
    searchKey: "",
    paymentStatus: undefined,
    testResultStatus: undefined,
  });
  const navigate = useNavigate();

  const [dataSource, setdataSource] = useState([]);

  const [userData, setuserData] = useState({});


  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You’ll get the total count of records from your API response
  });





  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"))?.data?.user;
    if(JSON.parse(localStorage.getItem("userData"))?.data?.user.role != 'compliance analyst'){
      setColumns( columns.filter((res)=> res.title !='Test Result Status'))
    }

    setuserData({
      ...data,
      role:data.role
    });
    getUser(filters,pagination.current ,pagination.page);
  }, []);

  const getUser = async (data,current,pageSize) => {
    const params = {
      page: current - 1,
      size: pageSize,
      testResultStatus: data.testResultStatus,
      paymentStatus: data.paymentStatus,
      searchKey: data.searchKey,
    };
    console.log(params)
    setloading(true);
    try {
      const response = await Axios.get(`getPatients`, params);
      if (response.status === 200) {
        console.log(response);
        setloading(false);
        setPagination((prop)=>({
          ...prop,
          total: response?.data?.matchingResults || 0, // Set total from the response
        }));
        const data = response?.data?.patients?.map((res)=>{ return {...res,key:res?.id}})
        setdataSource(data.map((res)=> { return {...res,date:dateFormat(res.date)}}));

      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };
 const handleViewDetails=(data)=>{
  console.log(data)
  navigate(`/patient-details/${data}`);
  }

  const [columns , setColumns]= useState([
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      align: 'center',
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      align: 'center',
    },
    {
      title: "Recieved Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => a?.date.localeCompare(b?.date),
      align: 'center',
    },
    {
      title: "Test Result Status",
      dataIndex: "testResultStatus",
      key: "testResultStatus",
      sorter: (a, b) => a.testResultStatus.localeCompare(b.testResultStatus),
      align: 'center',
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      sorter: (a, b) => a.paymentStatus.localeCompare(b.paymentStatus),
      align: 'center',
    },
    {
      title: "Action",
      key: "action",
      align: 'center',
      render: (_, record) => (
        <Space>

            <Button
              style={{ backgroundColor: "rgb(103 191 114)", color: "white" }}
              className="button-content1 "
              type="button"
              onClick={() => {
                console.log('lll')
                handleViewDetails(record.id)


              }}
            >
              <i className="bx bx-show"></i> View Details
            </Button>


        </Space>
      ),

    },
  ])






  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

 const rowSelection = {
  selectedRowKeys, // Bind the selectedRowKeys state
  onChange: onSelectedRowKeysChange, // Ensure we handle the change properly
  getCheckboxProps: (record) => ({
    disabled: record.disabled, // Example if you want to disable some rows (optional)
  }),
};

  const handleFilterChange = (key, value) => {
    // Update the filters object dynamically
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value || null, // Set to an empty string if cleared
    }));
  };
  const handlePaginationChange = (page, pagesize) => {
    setPagination((prev) => ({ ...prev, current:page, pageSize :pagesize }));
    getUser(filters,page, pagesize); // Use parameters directly
  }




  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid">
        <div className="heading-content">
          <h2>Orders</h2>
        </div>
        <div className="card custm-card">
          <div className=" form-container">
          <div className="Search-field">
              <label>Search </label>
              <input
                className="form-control"
                style={{ width: "400px" }}
                placeholder="Search..."
                value={filters.searchKey}
                onChange={(value) =>
                  handleFilterChange("searchKey", value.target.value)
                }
              ></input>
 </div>
 {userData.role == 'compliance analyst' && <div className="Search-field" style={{marginLeft:'9px'}}>
              <label>Test Result Status</label>
                <Select
                  style={{ width: 200, height: 40 }}
                  allowClear
                  value={filters.testResultStatus}
                  options={[
                    { value: "Pending", label: "Pending" },
                    { value: "Negative", label: "Negative" },
                    { value: "Positive", label: "Positive" },
                  ]}
                  placeholder="Test Result Status"
                  onChange={(value) =>
                    handleFilterChange("testResultStatus", value)
                  }
                />
</div>}
<div className="Search-field" style={{marginLeft:'9px'}}>
              <label>Payment Status</label>
                <Select
                  style={{ width: 200, height: 40 }}
                  allowClear
                  value={filters.paymentStatus}
                  options={[
                    { value: "Pending", label: "Pending" },
                    { value: "Paid", label: "Paid" },
                    { value: "Refund", label: "Refund" },
                  ]}
                  placeholder="Payment Status"
                  onChange={(value) =>
                    handleFilterChange("paymentStatus", value)
                  } // Update filter state
                />
</div>

          </div>
          <div className=" add-button">
            <Button
              type="primary"
              className="add-user-btn button"
              style={{ marginRight: "5px", padding: "18px" }}
              onClick={() => {
                setPagination({ ...pagination, current: 1 ,pageSize:10});
                getUser(filters, 1, 10);
              }}
            >
              <i className="bx bx-search"></i> Search
            </Button>
            <Button
              type="primary"
              danger
              className="button1"
              style={{ marginRight: "auto", padding: "18px" }}
              onClick={() => {
                setFilters({
                  searchKey: "",
                  paymentStatus: undefined,
                  testResultStatus: undefined,
                });
                setPagination({ ...pagination, current: 1 ,pageSize:10});
                getUser(
                  {
                    searchKey: "",
                    paymentStatus: undefined,
                    testResultStatus: undefined,
                  },
                  1,
                  10
                );
              }}
            >
              <i className="bx bx-x"></i>
              Clear
            </Button>

          </div>
        </div>

        <div className="main-contents table-responsive">
          <Table
            rowSelection={{
              type: "checkbox",
              preserveSelectedRowKeys: true,
              ...rowSelection,
            }}
            dataSource={dataSource}
            columns={columns}

            className="table-container custom-table2"
            pagination={{
              showSizeChanger: true,
              onChange: handlePaginationChange,

              current: pagination.current,
              pageSize: pagination.pageSize,

              total: pagination.total,

            }}
            locale={{
              emptyText: <div style={{ textAlign: 'center', color: 'black', fontSize: '16px' }}>No Data Found</div>,
            }}
          />

        </div>


      </div>
    </>
  );
};

export default Orders;
