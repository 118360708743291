import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "./ChangeLogo.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Axios from "../../Helpers/Interceptors";
import { Button } from "antd";
import Loader from "../Loader/Loader";
import {  emitEvent  } from '../../Helpers/utils';
const ChangeLogo = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  // Initial state to hold user data from localStorage
  const [initialValues, setInitialValues] = useState({
    text: "",

  });
  const [editProfile, setEditProfile] = useState(false);
  // Load user data from localStorage on component mount
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"))?.data?.user;
    console.log(data);


    getLogoName()
    // if (userData) {
    //   setInitialValues({
    //     email:''

    //   });
    // }
  }, []);

  const validationSchema = Yup.object().shape({
    text: Yup.string()
      .required("Name is required *")


  });

  const handleSubmit = async (values) => {

    try {
      setloading(true);


      let obj = {

        text: values.text,


      };

      let response = await Axios.put("auth/updateText", obj);
      if (response.status == 200) {

        localStorage.setItem('Logo',response.data.text)
        emitEvent('customEvent', { message: 'Hello from ComponentA!' });
        setloading(false);
      }
      getLogoName()
      setEditProfile(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
      setEditProfile(false);
      setloading(false);
    }
  };



  const getLogoName = async () => {

    try {
      setloading(true);




      let response = await Axios.get("auth/getText");
      if (response.status == 200) {
        // Checks if status code is 200 (OK)
       // Adjust this based on your API response structure

        // Save the updated user data in localStorage
        setInitialValues({
              text:response.data.text

            });


        setloading(false);
      }

      setEditProfile(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
      setEditProfile(false);
      setloading(false);
    }
  };


  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid">
        <div className="signup-content1 ">
          <div className="heading-content">
            <h2>Logo Name</h2>
          </div>
          {!editProfile && (
            <div className="add-button">
              <Button
                type="primary"
                className="add-user-btn"
                onClick={() => setEditProfile(true)}
              >
                <i className="bx bx-edit"></i> Edit Logo Name
              </Button>
            </div>
          )}

          <Formik
            validationSchema={validationSchema}
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
              setEditProfile(false);
            }}
          >
            {({ errors, touched, resetForm, setFieldValue }) => (
              <Form id="signup-form" className="signup-form container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field
                        onChange={(e) =>
                          setFieldValue("text", e.target.value.trimStart())
                        }
                        autoComplete="off"
                        className={`form-input ${
                          errors.text && touched.text ? "error" : ""
                        }`}
                        name="text"
                        placeholder="Logo Name*" maxlength="10"
                        disabled={!editProfile}
                      />
                      <ErrorMessage name="text">
                        {(msg) => (
                          <span className="error-message">
                            <i className="fa-solid fa-circle-info fa-xl"></i>
                            {msg}
                          </span>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>

                </div>



                <div className="submit-container">
                  {editProfile ? (
                    <>
                      <button className="submit-button1" type="submit">
                        Update
                      </button>
                      <button
                        type="button"
                        className="submit-button2"
                        onClick={() => {
                          resetForm();
                          setEditProfile(false);
                        }}
                      >
                        Cancel
                      </button>
                    </>
                  ) : null}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ChangeLogo;
