// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-content2 {
  background: #fff;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  padding: 23px 11px;
}

.graph-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none;
}

.recharts-sector:focus {
  outline: none;
}

.hidden-table {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Dashboard.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,wBAAA;EACA,2BAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;AACJ;;AAGE;EACA,2CAAA;EACF,YAAA;AAAA;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,aAAA;AADF","sourcesContent":[".signup-content2 {\r\n    background: #fff;\r\n    border-radius: 10px;\r\n    -moz-border-radius: 10px;\r\n    -webkit-border-radius: 10px;\r\n    -o-border-radius: 10px;\r\n    -ms-border-radius: 10px;\r\n    padding: 23px 11px;\r\n\r\n  }\r\n\r\n  .graph-card{\r\n  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\r\nborder: none;}\r\n\r\n\r\n\r\n.recharts-sector:focus {\r\n  outline: none;\r\n}\r\n\r\n.hidden-table{\r\n  display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
