import React, { useState, useEffect ,useRef } from "react";
import "./Dashboard.scss";
import { PieChart, Pie, Tooltip, ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Bar, Line, Legend ,Cell,Label} from 'recharts';
import { Table, Button, Space, Select,DatePicker ,TimePicker} from "antd";
import { toast } from "react-toastify";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';  // Import the timezone plugin
import utc from 'dayjs/plugin/utc';
import Axios from "../../Helpers/Interceptors";
import dayjs from 'dayjs';
import {dateFormat} from '../../Helpers/utils'
dayjs.extend(customParseFormat);
dayjs.extend(utc);  // Extend dayjs with the utc plugin
dayjs.extend(timezone);
const Dashboard = () => {
  const [userData, setuserData] = useState({});
  const [loading, setloading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [data01, setdata01] = useState([]);
  const [data02, setdata02] = useState([]);
  const [data03, setdata03] = useState([]);
  const [count, setcount] = useState({});

  const convertDateFormat = (inputDate) => {
    // Parse the input date string (MM/DD/YY format)
    const date = dayjs(inputDate, 'MM/DD/YY');

    // Format the date to 'YYYY-MM-DD'
    const formattedDate = date.format('YYYY-MM-DD');

    return formattedDate;
  };

  const [filters, setFilters] = useState({
    // patientName: undefined,
    status: undefined,
    location: undefined,
  });
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  startDate.setMonth(currentDate.getMonth() - 1)
  console.log(currentDate,startDate)

  const [startValue, setStartValue] = useState( dateFormat(startDate));
  const [endValue, setEndValue] = useState( dateFormat(currentDate));
  const [endOpen, setEndOpen] = useState(false);


  const onStartChange = (value) => {
    setStartValue(value);
  };

  const onEndChange = (value) => {
    setEndValue(value);
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };
  const disabledStartDate = (startValue) => {
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };


  const disabledEndDate = (endValue) => {
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };


  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData"))?.data?.user;
    console.log(data);


    if(data.role != 'compliance analyst'){
      setColumns( columns.filter((res)=> res.title !='Finger Printing'))
    }









    setuserData({
      startDate: startValue,
      endDate: endValue,
      searchKey: searchText,
      role:data.role
    });





   if(data.role == 'compliance analyst') {
    getDashboardData(
      { startDate: startValue, endDate: endValue},

    )
   }

    getRegistrationData(
      { startDate: startValue, endDate: endValue},

    );
  }, []);

  const getDashboardData = async (data,filter) => {
    const params = {

      startDate: data?.startDate
        ? convertDateFormat(data?.startDate)
        : undefined,
      endDate: data?.endDate ? convertDateFormat(data?.endDate) : undefined,
      ...filter
    };

    setloading(true);
    try {
      const response = await Axios.get(`dashboard/getAppointmentTrends`, params);
      if (response.status === 200) {
        setloading(false);
        console.log(response)
        setcount(response.data)
        setdata01([
          { name: 'Tests Failed', value: response.data?.testsFailed, fill: '#D32F2F' },  // Strong Red for failed tests
          { name: 'Tests Passed', value: response.data?.testsPassed, fill: '#388E3C' },  // Green for passed tests
          { name: 'Tests Pending', value: response.data?.testsPending, fill: '#FF9800' },  // Orange for pending tests (lighter tone)
          { name: 'No Status', value: response.data?.testsNoStatus, fill: '#757575' },  // Gray for no status
          { name: 'Tests InProgress', value: response.data?.testsInProgress, fill: '#1976D2' },

        ]);


      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const getRegistrationData = async (data,filters) => {
    const params = {

      startDate: data?.startDate
        ? convertDateFormat(data?.startDate)
        : undefined,
      endDate: data?.endDate ? convertDateFormat(data?.endDate) : undefined,
        ...filters
    };

    setloading(true);
    try {
      const response = await Axios.get(`dashboard/getMonthlyRegistrationTrends`, params);
      if (response.status === 200) {
        setloading(false);
        console.log(response)
setdata02( [
  { name: 'JAN', Total: response.data['JANUARY'] },
    { name: 'FEB', Total: response.data['FEBRUARY']},
    { name: 'MAR', Total: response.data['MARCH'] },
    { name: 'APR', Total: response.data['APRIL'] },
    { name: 'MAY', Total: response.data['MAY'] },
    { name: 'JUN', Total: response.data['JUNE'] },
    { name: 'JUL', Total: response.data['JULY'] },
    { name: 'AUG', Total: response.data['AUGUST'] },
    { name: 'SEP', Total: response.data['SEPTEMBER'] },
    { name: 'OCT', Total: response.data['OCTOBER'] },
    { name: 'NOV', Total: response.data['NOVEMBER'] },
    { name: 'DEC', Total: response.data['DECEMBER'] },
 // Different color
])


      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const targetDivRef = useRef(null);
  const [tableType, setTableType] = useState({
    key:'',
    table:''
  });

  const handlePieClick = (e,type) => {
console.log(e,type)

if (targetDivRef.current) {
  // Scroll to the div with the ref
  targetDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
}
    if(type == 'pichart'){
      let data= e.name === 'Tests Failed' ? 'fail' : e.name === 'Tests Passed' ? 'pass' : e.name === 'Tests Pending' ? 'pending' : e.name === 'No Status' ? 'no status' : e.name === 'Tests InProgress' ? 'InProgress' : ''
      setTableType({
        key:data,
        table:type
      });

      getChartTable(data,type)
    }else{

      let data= e.activeLabel == 'JAN' ? 'january' : e.activeLabel == 'FEB' ? 'february' : e.activeLabel == 'MAR' ? 'march' : e.activeLabel == 'APR' ? 'april' : e.activeLabel == 'MAY' ? 'may' : e.activeLabel == 'JUN' ? 'june' : e.activeLabel == 'JUL' ? 'july' : e.activeLabel == 'AUG' ? 'august' : e.activeLabel == 'SEP' ? 'september' : e.activeLabel == 'OCT' ? 'october'  : e.activeLabel == 'NOV' ? 'november' : e.activeLabel == 'DEC' ? 'december':''

      setTableType({
        key:data,
        table:type
      });
      if(data){
        getGraphTable(data)
      }

    }
  };


  const getChartTable = async (key,type) => {
    console.log(key)
    const params = {
      status:key,
      startDate:startValue
        ? convertDateFormat(startValue)
        : undefined,
      endDate:endValue ? convertDateFormat(endValue) : undefined,
      location:filters.location


    };
    console.log(params)
    setloading(true);
    try {
      const response = await Axios.get(`dashboard/getSpecificAppointmentTrend`, params);
      if (response.status === 200) {
        console.log(response)
        setloading(false);

          const transformedData = response.data.content?.map((item) => ({
            ...item,
            date:dateFormat(item.date),
            firstName:item?.patient?.firstName,
            lastName:item?.patient?.lastName,
            phoneNumber:item?.patient?.phoneNumber,
            email:item?.patient?.email,

            key:item?.id
          }));
          console.log(transformedData)
          setdata03(transformedData);

      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const getGraphTable = async (data) => {
    const params = {

      startDate:startValue
      ? convertDateFormat(startValue)
      : undefined,
    endDate:endValue ? convertDateFormat(endValue) : undefined,
    ...filters

    };

    setloading(true);
    try {
      const response = await Axios.get(`dashboard/getSpecificMonthRegistrationData/${data}`,params);
      if (response.status === 200) {
        setloading(false);
        const transformedData = response.data?.map((item) => ({
          ...item,
          date:dateFormat(item.date),
          firstName:item?.patient?.firstName,
          lastName:item?.patient?.lastName,
          phoneNumber:item?.patient?.phoneNumber,
          email:item?.patient?.email,

          key:item?.id
        }));
        console.log(transformedData)
        setdata03(transformedData);


      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };



  const handleFilterChange = (key, value) => {
    // Update the filters object dynamically
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value || null, // Set to an empty string if cleared
    }));
  };

  const [columns , setColumns]= useState([
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: 'center',
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: 'center',
      sorter: (a, b) => a.time.localeCompare(b.time),
    },
    {
      title: "Location",
      dataIndex: "location", // Accessing nested patient.location
      key: "location",
      align: 'center',
      sorter: (a, b) => a.location.localeCompare(b.location),
      render: (location) => location, // Display the location
    },
    {
      title: "First Name",
      dataIndex: "firstName", // Accessing nested patient.firstName
      key: "firstName",
      align: 'center',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (firstName) => firstName, // Display first name
    },
    {
      title: "Last Name",
      dataIndex: "lastName", // Accessing nested lastName
      key: "lastName",
      align: 'center',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      render: (lastName) => lastName, // Display last name
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber", // Accessing nested phoneNumber
      key: "phoneNumber",
      align: 'center',
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      render: (phoneNumber) => phoneNumber, // Display phone number
    },
    {
      title: "Email Id",
      dataIndex: "email", // Accessing nested email
      key: "email",
      align: 'center',
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (email) => email, // Display email
    },
    {
      title: "Finger Printing",
      dataIndex: "status", // Status is not nested, so directly use 'status'
      key: "status",
      align: 'center',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => capitalizeFirstLetter(status), // Display status
    },
    {
      title: "Appointment Status",
      dataIndex: "testingStatus", // Status is not nested, so directly use 'status'
      key: "testingStatus",
      align: 'center',
      sorter: (a, b) => a.testingStatus.localeCompare(b.testingStatus),
      render: (testingStatus) => capitalizeFirstLetter(testingStatus), // Display status
    },

  ])




  function capitalizeFirstLetter(str) {
    if (!str) return str;  // Check if the string is empty or null
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <div className="container-fluid">
      <div className="heading-content">
        <h2>Dashboard</h2>
      </div>

      <div className="card custm-card">
        <div className="form-container">
        <div className="Search-field" style={{ marginLeft: "10px"}}>
              <label>Start Date</label>
          <DatePicker
                  style={{ padding: "8px" }}
                  disabledDate={disabledStartDate}
                  format="MM-DD-YY"
                  value={startValue ? dayjs(startValue, 'MM-DD-YY') : null}

                  placeholder="MM-DD-YY"

                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                />
               </div>
               <div className="Search-field" style={{ marginLeft: "10px"}}>
              <label>End Date</label>
                <DatePicker
                 style={{ padding: "8px" }}
                  disabledDate={disabledEndDate}
                  format="MM-DD-YY"

                  value={endValue ? dayjs(endValue, 'MM-DD-YY') : null}
                  placeholder="MM-DD-YY"
                  onChange={onEndChange}
                  open={endOpen}
                  onOpenChange={handleEndOpenChange}
                />
</div>
{userData.role == 'compliance analyst' && <div className="Search-field" style={{ marginLeft: "10px"}}>
              <label>Finger Printing</label>
              <Select
                style={{ width: 200, height: 40 }}
                allowClear
                value={filters.status}
                options={[
                  { value: "InProgress", label: "InProgress" },
                  { value: "pass", label: "Pass" },
                  { value: "fail", label: "Fail" },
                  { value: "pending", label: "Pending" },
                  { value: "no status", label: "No status" },
                ]}
                placeholder="Finger Printing"
                onChange={(value) =>
                  handleFilterChange("status", value)
                }
              />
              {/* <Select
                style={{ width: 200, height: 40 }}
                allowClear
                value={filters.eventName}
                options={[
                  { value: "Pending", label: "Pending" },
                  { value: "Paid", label: "Paid" },
                  { value: "Unpaid", label: "Unpaid" },
                ]}
                placeholder="Medical Event"
                onChange={(value) =>
                  handleFilterChange("eventName", value)
                }
              /> */}
              {/* <Select
                style={{ width: 200, height: 40 }}
                allowClear
                value={filters.patientName}
                options={[
                  { value: "John", label: "John" },
                  { value: "Jane", label: "Jane" },
                  { value: "Michael", label: "Michael" },
                ]}
                placeholder="Patient Name"
                onChange={(value) =>
                  handleFilterChange("patientName", value)
                }
              /> */}
        </div>}
        <div className="Search-field" style={{ marginLeft: "10px"}}>
              <label>Location</label>
              <Select
                style={{ width: 200, height: 40 }}
                allowClear
                value={filters.location}
                options={[
                  { value: "delhi", label: "Delhi" },
                  { value: "pune", label: "Pune" },

                ]}
                placeholder="Location"
                onChange={(value) =>
                  handleFilterChange("location", value)
                }
              />

        </div>


        </div>

        <div className="add-button mt-2">
          <Button
            type="primary"
            className="add-user-btn button"
            style={{ marginRight: "5px", padding: "18px" }}  onClick={() => {
              setTableType({
                key:'',
                table:''
              });
              setdata03([])

              if(userData.role == 'compliance analyst') { getDashboardData({ startDate: startValue, endDate: endValue},filters)}
              getRegistrationData(
                { startDate: startValue, endDate: endValue},filters

              );

              // handlePieClick({name :tableType.key == 'fail' ? 'Tests Failed' : tableType.key == 'pass' ? 'Tests Passed' : tableType.key == 'pending' ? 'Tests Pending' : ''},tableType.table)
            }}

          >
            <i className="bx bx-search"></i> Search
          </Button>
          <Button
            type="primary"
            danger
            className="button1"
            style={{ marginRight: "auto", padding: "18px" }} onClick={() => {
                setStartValue(undefined);
                setEndValue(undefined);
setFilters( {
  status: undefined,
  location:undefined
 });


//  setStartValue(startDate.toISOString().split('T')[0].replace(/-/g, '/'))
//  setEndValue(currentDate.toISOString().split('T')[0].replace(/-/g, '/'))
if(userData.role == 'compliance analyst'){
              getDashboardData({ startDate: undefined, endDate: undefined},{
                status: undefined,
                location:undefined
                })}
                getRegistrationData({ startDate: undefined, endDate: undefined},{
                  status: undefined,
                  location:undefined
                  });


                setTableType({
                  key:'',
                  table:''
                });
                setdata03([])
            }}
          >
            <i className="bx bx-x"></i> Clear
          </Button>
        </div>
      </div>

      <div className="signup-content2">

        <div className="row m-3" >
           {userData.role == 'compliance analyst' && <div className="col-6">
            <div className="heading-content">
        <h2>Finger Printing</h2>
      </div>
          {(userData.role == 'compliance analyst' &&  count.totalRegistrations != 0) &&  <ResponsiveContainer width="100%" height={400}  className="card graph-card pie-chart-container"  >
          <PieChart width={700} height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={data01}
              cx="50%"
              cy="50%"
              outerRadius={80}
              label
              onClick={(e)=>handlePieClick(e,'pichart')}
            >
              {/* Optional: You can add a color per section here */}
              {data01.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.fill} className="mr-2"/>
              ))}
            </Pie>
            {/* Add Legend here */}
            <Legend />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>}
        { (userData.role == 'compliance analyst' &&  count.totalRegistrations == 0) && <div className="card graph-card pie-chart-container" style={{height:'86%'}}>  <h2 style={{marginTop:'30%'}}>No Data Found</h2>  </div>}

            </div>}

            <div className={userData.role == 'compliance analyst' ? 'col-6' : 'col-12'}>
            <div className="heading-content">
        <h2>Appointments</h2>
      </div>
            <ResponsiveContainer width="100%" height={400}  className="card graph-card">
            <ComposedChart
              width={500}
              height={400}
              data={data02}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
              onClick={(e)=>handlePieClick(e,'graph')}
            >


              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name"  scale="point" padding={{ left: 20, right: 10 }}>
      <Label
        value="Months" // Title for the X-axis
        position="insideBottom"
        offset={-10} // Moves the label a bit higher for visibility
        style={{ textAnchor: 'middle', fontSize: '14px', fontWeight: 'bold' }}
      />
    </XAxis>
    <YAxis>
      <Label
        value="Number of Appointments" // Title for the Y-axis
        angle={-90}  // Rotate the label to vertical
        position="insideLeft"
        style={{ textAnchor: 'middle', fontSize: '14px', fontWeight: 'bold' }}
      />
    </YAxis>
              <Tooltip />
              {/* <Legend /> */}
              <Bar dataKey="Total" barSize={20} fill="#413ea0" />
              {/* <Line type="monotone" dataKey="uv" stroke="#ff7300" /> */}
            </ComposedChart>
          </ResponsiveContainer>
            </div>


        </div>
      </div>


      <div className="signup-content2"  >
<div className={tableType.table ? "" : "hidden-table"}>  <div className="container " ref={targetDivRef}  >

{tableType.table =='pichart'  &&       <div className="heading-content" ><h2>Finger Printing</h2></div>} {tableType.table =='graph'  &&  <div className="heading-content"><h2>Appointments</h2></div>}
{tableType.table && <Table dataSource={data03} columns={columns}  locale={{
              emptyText: <div style={{ textAlign: 'center', color: 'black', fontSize: '16px' }}>No Data Found</div>,
            }}/>}

</div> </div>






    </div>
    </div>
  );
};

export default Dashboard;
