import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Switch,
  Modal,
  Dropdown,
  Space,
  Menu,
  Tooltip,
} from "antd";
import { LeftOutlined } from '@ant-design/icons';
import "./PatientDetails.scss";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import Axios from "../../Helpers/Interceptors";
import * as Yup from "yup";
import { UserOutlined } from "@ant-design/icons";
import Loader from "../Loader/Loader";
import { useParams,useNavigate } from "react-router-dom";

const PatientDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [data,setData]=useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectedRowKeysChange,
  };

  const [columns, setColumns] = useState([
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: "Test Type",
      dataIndex: "testType",
      key: "testType",
      sorter: (a, b) => a.testType.localeCompare(b.testType),

    },
    {
      title: "Lab Test Status",
      dataIndex: "status", // Accessing nested patient.location
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => capitalizeFirstLetter(status),

    },
    {
      title: "Appointment Status",
      dataIndex: "testingStatus", // Accessing nested patient.firstName
      key: "testingStatus",
      sorter: (a, b) => a.testingStatus.localeCompare(b.testingStatus),
      render: (testingStatus) => capitalizeFirstLetter(testingStatus),
    },

  ]);

  const getUserDeatils = async (id) => {

    setloading(true);
    try {
      const response = await Axios.get(`/getPatientById/${id}`);
      if (response.status === 200) {
        console.log(response);
        setloading(false);
        setData(response?.data?.patient)
        setdataSource(response?.data?.appointments)

      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 404
      ) {
        console.log("here");
        toast.error(response.response.data.message);
        setloading(false);
      }
      toast.error(response?.response?.data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const [userData, setuserData] = useState({});

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem("userData"))?.data?.user;

    setuserData({

      role:userDetail.role
    });
    if(userDetail.role != 'compliance analyst'){

      setColumns(columns.filter((res)=> res.title != 'Lab Test Status'))
    }


    getUserDeatils(id);
  }, []);



  function capitalizeFirstLetter(str) {
    if (!str) return str;  // Check if the string is empty or null
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return (
    <>
    {loading && <Loader />}
    <div className="container-fluid">
    <div className="heading-content" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {/* Back Button with Arrow */}
          <Button
            type="text"
            onClick={() => navigate(-1)}
            style={{ fontSize: '16px', display: 'flex', alignItems: 'center',color:'white' }}
          >
            <LeftOutlined style={{ fontSize: '20px', marginRight: '5px',color:'white' }} />

          </Button>
          <h2>Patient Details</h2>
        </div>
      <div className="row">

        <div className="col-md-7 col-lg-7 col-12">
          <div className="card p-2 box-card">
          <div className="main-contents table-responsive">
          <div className="heading-content">
          <h2>Lab Testing History</h2>
           </div>
          <Table
            // rowSelection={{
            //   type: "checkbox",
            //   preserveSelectedRowKeys: true,
            //   ...rowSelection,
            // }}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            className="table-container"
             locale={{
              emptyText: <div style={{ textAlign: 'center', color: 'black', fontSize: '16px' }}>No Data Found</div>,
            }}
          />
          {/* <Pagination
            className="custm-pagination"
            showSizeChanger
            onShowSizeChange={(current, pageSize) => {
              setPagination({ ...pagination, current, pageSize });
              getUser(filters, current, pageSize);
            }}
            onChange={(current, pageSize) => {
              setPagination({ ...pagination, current, pageSize });
              getUser(filters, current, pageSize);
            }}
            pageSize={pagination.pageSize} // Ensures it reflects the correct value
            pageSizeOptions={["5", "10", "20", "50", "100"]}
            total={pagination.total}
          /> */}
        </div>
          </div>
        </div>
        <div className="col-md-5 col-lg-5 col-12">
          <div className="card p-2 box-card">
          <div className="heading-content">
          <h2>Basic Information</h2>
           </div>
              <h5 className="name-patient">{data?.firstName} {data?.lastName}</h5>
              <hr className="line-border"/>
              <div className="row mt-3">
                <div className="col-md-4 col-lg-4 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Customer ID</h5>
                    <p>CID001</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Email</h5>
                    <p>{data.email ? data.email : 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Phone Number</h5>
                    <p>{data.phoneNumber ? data.phoneNumber : 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Registration Date</h5>
                    <p>{data.date ? data.date : 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>Address</h5>
                    <p>{data.address ? data.address : 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>City</h5>
                    <p>{data.city ? data.city : 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>State</h5>
                    <p>{data.state ? data.state : 'N/A'}</p>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-12 mb-3">
                  <div className="flex-detail">
                    <h5>ZIP</h5>
                    <p>{data.zip ? data.zip : 'N/A'}</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PatientDetails;
